import React from 'react';
import { Popover, Table } from 'antd';
import './index.less';

const ImageView = Loader.loadBaseComponent('ImageView');
const IconFont = Loader.loadBaseComponent('IconFont');

class InfoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: []
    };
  }

  onSelectChange = selectedRowKeys => {
    const { list, crossValue } = this.props;
    this.setState({
      selectedRowKeys
    });
    let arr = list.filter(item => selectedRowKeys.find(v => v === item.vehiclePlateNo));
    crossValue && crossValue(arr);
  };

  render() {
    const { selectedRowKeys } = this.state;
    let { list, editVehicle } = this.props;
    const columns = [
      {
        title: '照片',
        dataIndex: 'vehicleUrl',
        width: '5%',
        render: (vehicleUrl, item) => {
          let url = vehicleUrl;
          return url ? (
            <Popover
              trigger="hover"
              placement="right"
              overlayClassName="people-info-pop"
              content={
                <div className="people-box">
                  <ImageView className="box-img" src={url} />
                </div>
              }>
              <div className="list-pop-box">
                <img className="pop-img" src={url && url} alt="" />
              </div>
            </Popover>
          ) : (
            <div className="list-pop-box">
              <img className="pop-img" src={url && url} alt="" />
            </div>
          );
        }
      },
      {
        title: '车牌号码',
        dataIndex: 'vehiclePlateNo',
        width: '8%'
      },
      {
        title: '车辆品牌',
        dataIndex: 'vehicleBrand',
        width: '8%',
        render: vehicleBrand => {
          const text = Dict.getLabel('vehicleBrands', vehicleBrand);
          return text ? text : <span className="vehicle-color-error">{vehicleBrand}</span>;
        }
      },
      {
        title: '车系',
        dataIndex: 'vehicleCatena',
        width: '6%'
      },
      {
        title: '车型',
        dataIndex: 'vehicleType',
        width: '6%'
      },
      {
        title: '车辆颜色',
        dataIndex: 'vehicleColor',
        width: '8%',
        render: vehicleColor => {
          const chose = Dict.map.vehicleColor.find(v => v.value == vehicleColor);
          return chose ? chose.text : <span className="vehicle-color-error">{vehicleColor}</span>;
        }
      },
      {
        title: '车主姓名',
        dataIndex: 'ownerName',
        width: '8%',
        render: (ownerName, data = {}) => {
          const { residentialArea, isAssociation } = data;
          const type = residentialArea === '0' ? false : true;
          return (
            <div>
              {type && <IconFont className={`owner-icon ${isAssociation ? 'green' : 'red'}`} type="icon-S_Bar_Linked" theme="outlined" />} {ownerName}
            </div>
          );
        }
      },
      {
        title: '是否小区居民',
        dataIndex: 'residentialArea',
        width: '10%',
        render: residentialArea => (residentialArea === '0' ? '否' : '是')
      },
      // {
      //   title: '车主住址',
      //   dataIndex: 'ownerAddress',
      //   width: '16%',
      //   render: ownerAddress => (
      //     <div className="owner-address" title={ownerAddress}>
      //       {ownerAddress}
      //     </div>
      //   )
      // },
      {
        title: '楼栋号',
        dataIndex: 'ownerBuildingNumber',
        width: '6%',
        render: ownerBuildingNumber => {
          if ((ownerBuildingNumber + '').includes('不填')) {
            return '暂无';
          } else {
            return ownerBuildingNumber;
          }
        }
      },
      {
        title: '单元号',
        dataIndex: 'ownerUnitNumber',
        width: '6%',
        render: ownerUnitNumber => {
          if ((ownerUnitNumber + '').includes('不填')) {
            return '暂无';
          } else {
            return ownerUnitNumber;
          }
        }
      },
      {
        title: '房间号',
        dataIndex: 'ownerRoomNumber',
        width: '6%',
        render: ownerRoomNumber => {
          if ((ownerRoomNumber + '').includes('不填')) {
            return '暂无';
          } else {
            return ownerRoomNumber;
          }
        }
      },
      {
        title: '上传状态',
        dataIndex: 'uploadStatus',
        render: uploadStatus => (
          <div className="import-status">
            <span className={`status-span ${uploadStatus === 1 ? 'green' : uploadStatus === 2 ? 'blue' : uploadStatus === 3 ? 'yellow' : 'red'}`} />
            {uploadStatus === 1 ? '信息完整' : uploadStatus === 2 ? '暂无图片' : uploadStatus === 3 ? '图片特征值提取失败' : '信息验证失败'}
          </div>
        )
      },
      {
        title: '操作',
        dataIndex: '',
        width: '4%',
        render: (text, record, index) => {
          return (
            <IconFont
              className="edit-icon"
              type="icon-S_Edit_Edit"
              theme="outlined"
              onClick={() => {
                editVehicle && editVehicle(record);
              }}
            />
          );
        }
      }
    ];
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };
    return (
      <div className="info-list-view">
        <Table
          childrenColumnName="info-list-table"
          rowKey={record => record.vehiclePlateNo}
          rowClassName="info-list-table-row"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </div>
    );
  }
}

export default InfoList;
