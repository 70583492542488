import React, { Component } from 'react';
import { message, Modal } from 'antd';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import InfoList from './components/InfoList';
import './index.less';

const TwoColumnLayout = Loader.loadBaseComponent('TwoColumnLayout');
const InfoFilter = Loader.loadBusinessComponent('InformationEntry', 'InfoFilter');
const InfoConfirm = Loader.loadBusinessComponent('InformationEntry', 'InfoConfirm');
const Pagination = Loader.loadBaseComponent('Pagination');

const vehicleOption = [
  { value: -1, label: '全部上传状态', icon: 'icon-S_Bar_List' },
  { value: 1, label: '信息完整', color: 'green' },
  { value: 2, label: '暂无图片', color: 'blue' },
  { value: 3, label: '图片特征值提取失败', color: 'yellow' },
  { value: 4, label: '信息验证失败', color: 'red' }
];

const associationOption = [
  { value: -1, label: '全部关联状态', icon: 'icon-S_Bar_List' },
  { value: 1, label: '车主非小区居民' },
  { value: 2, label: '车主关联成功', icon: 'icon-S_Bar_Linked' },
  { value: 3, label: '车主关联失败', icon: 'icon-S_Bar_Linked' }
];

@withRouter
@Decorator.businessProvider('tab')
@observer
class VehicleEntry extends Component {
  constructor(props) {
    super(props);
    const { history } = props;
    const { id, villageName } = Utils.queryFormat(history.location.search);
    this.state = {
      villageId: id, //选中的社区Id
      villageName: decodeURI(villageName),
      list: [],
      visible: false,
      modalType: 0,
      loading: false,
      modalValue: {},
      choseList: [],
      total: 0,
      searchData: {
        villageId: id,
        status: -1,
        page: 1,
        pageSize: 10
      }
    };
    SocketEmitter.on(SocketEmitter.eventName.updateCommunityVehicle, this.getVillageVehicleList);
  }
  componentDidMount() {
    this.getVillageVehicleList();
  }

  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.updateCommunityVehicle, this.getVillageVehicleList);
  }

  getVillageVehicleList = ops => {
    let { searchData, villageId } = this.state;
    let searchDatas = { ...searchData, ...ops };
    let options = {
      status: searchDatas.status,
      villageId: villageId,
      offset: (searchDatas.page - 1) * searchDatas.pageSize,
      limit: searchDatas.pageSize,
      residentialArea: searchDatas.residentialArea,
      associationStatus: searchDatas.associationStatus
    };
    if (options.status === -1) {
      options.status = undefined;
    }
    Service.community.queryEnteringVehicles(options).then(res => {
      const list = res.list || [];
      list.forEach(v => {
        let text = '';
        if (v.ownerBuildingNumber && !(v.ownerBuildingNumber + '').includes('不填')) {
          text += v.ownerBuildingNumber + '栋';
        }
        if (v.ownerUnitNumber && !(v.ownerUnitNumber + '').includes('不填')) {
          text += v.ownerUnitNumber + '单元';
        }
        if (v.ownerRoomNumber && !(v.ownerRoomNumber + '').includes('不填')) {
          text += v.ownerRoomNumber + '室';
        }
        v.ownerAddress = text;
      });
      this.setState({
        searchData: searchDatas,
        total: res.total,
        list: res.list
      });
    });
  };

  onChange = (currentPage, pageSize) => {
    let { searchData } = this.state;
    searchData.page = currentPage;
    searchData.pageSize = pageSize;
    this.getVillageVehicleList(searchData);
  };

  ModalShow = () => {
    let { choseList, modalType } = this.state;
    if (choseList.length === 0 && modalType === 0) {
      message.info('请选择您要删除的车辆信息');
      return;
    }
    this.setState({
      modalType: 0,
      visible: !this.state.visible
    });
  };

  deleteEnteringVehicles = async () => {
    let { choseList, villageId, modalType } = this.state;
    if (choseList.length === 0 && modalType === 0) {
      message.info('请选择您要删除的车辆信息');
      return;
    }
    if (modalType === 0) {
      this.setState({
        loading: true
      });
      let option = {
        villageId,
        vehiclePlateNos: []
      };
      choseList.forEach(item => {
        option.vehiclePlateNos.push(item.vehiclePlateNo);
      });
      await Service.community.deleteEnteringVehicles(option);
      await this.setState({
        visible: false,
        loading: false,
        choseList: []
      });
    } else {
      await this.setState({
        visible: false
      });
    }
    this.getVillageVehicleList();
  };

  uploadZip = fileUrl => {
    let { villageId } = this.state;
    Service.community.uploadVehicleFile(fileUrl, villageId).then(res => {
      message.info(res.message);
    });
  };

  crossValue = selectedRowKeys => {
    this.setState({ choseList: selectedRowKeys });
  };

  modalInfoDestroy = modalInfo => {
    this.setState({ modalInfo });
  };

  editVehicle = parms => {
    const { tab, location } = this.props;
    const { villageId, villageName } = this.state;
    const id = Utils.uuid();
    LM_DB.add('parameter', {
      id,
      data: parms,
      villageId, //选中的社区Id
      villageName
    }).then(() => {
      tab.goPage({
        moduleName: 'vehicleEdit',
        location,
        data: { id }
      });
    });
  };

  render() {
    const { villageName, villageId, searchData, total, list = [], modalType, visible, loading, modalValue = {}, choseList } = this.state;
    return (
      <TwoColumnLayout title={villageName}>
        <div className="vehicle-entry-view">
          <InfoFilter
            type="vehicle"
            search={this.getVillageVehicleList}
            villageId={villageId}
            choseList={choseList}
            uploadZip={this.uploadZip}
            searchData={searchData}
            villageName={villageName}
            deletePeople={this.ModalShow}
            uploadType="local"
            associationOption={associationOption}
            optionList={vehicleOption}
          />
          <div className="vehicle-entry-list">
            <InfoList list={list} crossValue={this.crossValue} editVehicle={this.editVehicle} />
            <Pagination
              total={total}
              onChange={this.onChange}
              onShowSizeChange={this.onChange}
              current={searchData.page}
              pageSize={searchData.pageSize}
              pageSizeOptions={['10', '20', '30', '40', '50']}
            />
          </div>
        </div>
        <InfoConfirm type={modalType} onOk={this.deleteEnteringVehicles} loading={loading} visible={visible} onCancel={this.ModalShow} messType="vehicle" data={modalValue} />
      </TwoColumnLayout>
    );
  }
}

export default VehicleEntry;
